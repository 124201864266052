.wrapper {
  height: 100lvh;
  background-color: #1F2C34;
  border-bottom: 1px solid #2f4655;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  
  h1 {
    margin: 0 10px;
  }

  p {
    font-size: 20px;
    margin: 0 10px;
  }

  button {
    cursor: pointer;
    margin-top: 30px;
    padding: 15px 50px;
    gap: 10px;
    background-color: #fff;
    color: var(--1F2C34, #1F2C34);
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
}