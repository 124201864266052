.contactWrapper {

  .hero {
    height: 400px;
    padding: 50px 70px;
    background-color: #1F2C34;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #fff;

    a {
      color: var(--FFFFFF, #FFF);
      text-decoration: none;
      /* Heading 6 */
      font-family: Jost;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
    }

    div {
      display: flex;
      gap: 60px;
      div {
        gap: 10px;
        flex-direction: column;
      }
    }
  }
  .section2 {
    display: flex;
    gap: 150px;
    padding: 100px 150px;
    justify-content: center;
    .itemsWrapper {
      justify-content: space-between;
      display: flex;
      width: 100%;
    }

    .entriesWrapper {
      display: flex;
      justify-content: space-between;
      width: 500px;
      height: 500px;
      flex-direction: column;
      h2 {
        color: var(--1F2C34, #1F2C34); 
      }

    }

    iframe {
      width: 500px;
      height: 500px;
    }
  }
  .section3 {
    height: 600px;
    align-self: stretch;
    object-fit: cover;
    background: url(../../assets/contactImage1.jpg) lightgray 50% / cover no-repeat;
  }
}

@media screen and (max-width:1080px) {
  .contactWrapper {

    .hero {
      height: 448px;
      padding: 110px 15px 50px 15px;
      align-items: flex-start;
      flex-direction: column;

      h2 {
        font-size: 48px;
        line-height: 50px;
      }

      div {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 30px;

        h5 {
          font-size: 20px;
          line-height: 22px;
        }
        h6 {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .section2 {
      flex-direction: column;
      padding: 60px 15px;
      gap: 30px;
      justify-content: space-between;

      iframe {
        width: calc(100vw - 30px);
        height: calc(100vw - 30px);
      }

      .entriesWrapper {
        width: calc(100vw - 30px);
        height: unset;
      }
    }
    .section3 {
      height: 500px;
    }
  }
}