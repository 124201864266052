.contactUs {
  flex-shrink: 1;
  background-color: #EDF0F5;
  width: calc(100% - 20%);
  height: calc(100% - 200px);
  padding: 100px 10%;
  display: flex;
  justify-content: center;
  gap: 145px;

  .textBoxes {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 70px;
    .text {
      p {
        color: var(--394d8b, #394D8B);
        font-family: "Monsieur La Doulaise";
        font-size: 46px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 86.957% */
      }
      h3 {
        color: var(--1F2C34, #1F2C34); 
      }
    }
  }
  .kosovoMap {
    position: relative;
    .dot {
      position: absolute;
      left:195px;
      top:595px;
      background-color: #1F2C34;
      border-radius: 100px;
      width: 26px;
      height: 26px;
    }
    .mapText {
      width: 115px;
      height: 50px;
      position: absolute;
      left: 120px;
      top: 600px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .contactUs {
    padding: 40px 15px;
    width: calc(100% - 30px);
    flex-direction: column;
    align-items: center;
    gap: 50px;
    .textBoxes {
      width: 100%;
      gap: 30px;
      .text {
        p {
          font-size: 30px;
        }

        h3 {
          font-size: 38px;
        }
      }
    }
    .kosovoMap {
      width: 330px;
      .dot {
        width: 18px;
        height: 18px;
        left: 125px;
        top: 383px;
      }
      img {
        width: 100%;
      }
      .mapText {
        left: 55px;
        top: 383px;
      }
    }
  }
}