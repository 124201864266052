.headerWrapper {
  position: absolute;
  padding: 15px 40px;
  width: calc(100% - 80px);
  height: 70px;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;

  svg {
    position: absolute;
    left: calc(50% - 72px);
    cursor:pointer;
  }

  button {
    background-color: rgba($color: #000000, $alpha: 0);
    border: 1px solid #fff;
    padding: 14px 38px;
    
    color: var(--FFFFFF, #fff);
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 125% */

    &:hover {
      cursor: pointer;
    }
  }

  .subpages {
    width: 33.333%;
    display: flex;
    gap: 20px !important;

    button {
      margin-top: 20px;
    }
    p {
      white-space: nowrap;
      position: relative;
      color: #fff;
      font-family: Jost;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 133.333% */
      text-transform: uppercase;

      &:before {
        content: "";
        width: 0;
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: #fff;
        transition: 0.2s;
      }
      &:hover {
        cursor: pointer;
        &:before {
          width: 100%;
        }
      }
    }
    .mobileShelfButton {
      cursor: pointer;
      z-index: 100;
      display: none;
    }
  }

  .shelf {
    z-index: -1;
    position: fixed;
    width: 100lvw;
    left: 0;
    top: 0;
    height: 200lvh;
    transition: top 0.5s ease 0s;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(31,44,52,1) 50%, rgba(31,44,52,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(31,44,52,1) 50%, rgba(31,44,52,1) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(31,44,52,1) 50%, rgba(31,44,52,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1f2c34",GradientType=1); 
    
    .subpages {
      height: calc(50% - 110px);
      padding-top: 110px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      p {
        position: relative;
        color: var(--FFFFFF, #FFF);
        font-family: Jost;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 100% */
        text-transform: uppercase;
  
        &:before {
          content: "";
          width: 0;
          position: absolute;
          bottom: 0;
          height: 2px;
          background-color: #fff;
          transition: 0.2s;
        }
        &:hover {
          cursor: pointer;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .languages {
    position: absolute;
    display: flex;
    right: 271px;
    gap: 20px;
    p {
      cursor: pointer;
      color: #FFF;
      font-family: Jost;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
}

@media screen and (max-width:1080px) {
  .headerWrapper {
    padding: 10px 15px;
    width: calc(100vw - 30px);
    height: 50px;

    svg {
      position: relative;
      left: 0;
      top: 0;
      width: 100px;
      height: 50px;
    }

    .subpages {
      display: none; //needs to be shown later
    }

    button {
      padding: 14px 20px;
      display: none; //also needs to be shown later
    }

    .mobileShelfButton {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 36px;
      border: 1px solid var(--FFFFFF, #FFF);

      .line {
        pointer-events: none;
        width: 30px;
        height: 1px;
        background-color: #fff;
      }
    }

    .languages {
      right: 70px;
    }

  }
}