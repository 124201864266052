* {
  margin: 0;
  padding: 0;
  border: 0;
}

h1 {
  /* Heading 1 */
  font-family: Forum;
  font-size: 120px;
  font-style: normal;
  font-weight: 400;
  line-height: 124px; /* 103.333% */
  letter-spacing: -2.4px;
}
h2 {
  /* Heading 2 */
  font-family: Forum;
  font-size: 68px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 102.941% */
}
h3 {
  /* Heading 3 */
  font-family: Forum;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 86.957% */
}
h4 {
  /* Heading 4 */
  font-family: Forum;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 135.714% */
}
h5 {
  font-family: Forum;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 109.091% */ 
}
h6 {
  /* Heading 6 */
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}

p {
  /* Paragraph */
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
